body,
html {
	font-family: Poppins, Nunito, "Open Sans", Roboto, "Consolas";
	font-size: 20px;
	background-color: #e5e5e5;
}

h1 {
	font-size: 2rem;
	@include md {
		font-size: 2.5rem;
	}
}

h2 {
	font-size: 1.5rem;
	@include md {
		font-size: 2rem;
	}
}

.pageWrapper {
	@include flexbox;
	@include md {
		justify-content: flex-end;
	}
	width: 100%;
}

.btn {
	background-color: $mc;

	&:focus {
		outline: none;
		box-shadow: none;
	}

	&.btn-primary {
		background-color: $mc;

		&:hover {
			background-color: $mc-dark;
		}
	}
}

.btn-sc {
	background-color: $sc-light2;

	&:hover {
		background-color: $sc-light;
	}
}

#carouselControls {
	max-height: 550px;
	overflow: hidden;

	.carousel-control-next,
	.carousel-control-prev {
		@include transition(0.8s);

		&:hover {
			background-color: rgba(#000, 0.4);
		}
	}

	.carousel-indicators {
		min-height: 30px;
		align-items: flex-end;

		li {
			height: 5px;
			width: 20px;
			cursor: pointer;
			border-radius: 0;
			@include transition(0.8s);

			&.active {
				width: 20px;
				height: 20px;
				border-radius: 4px;
			}
		}
	}

	.carousel-item {
		//position: relative;
		max-height: 550px;

		.textWrapper {
			position: absolute;
			background-color: rgba(#fff, .6);
			padding: 10px;
			margin: 10px;
			border-radius: 0;
			bottom: 0;
			right: 0;
			z-index: 2;
			max-width: 60%;
		}

		img {
			z-index: 1;
		}
	}
}

.mg-top {
	margin-top: 50px;
}

.catchphrasebg {
	text-align: center;
	margin: auto 0;
	font-size: 88px;
	color: #aaa;
	text-align: center;
	text-shadow: 2px 2px 2px 2px #000;
	background-color: #565656;
	color: transparent;
	text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
}

.skills {
	> .row {
		margin-top: 20px;
	}

	.icon {
		text-align: center;
		align-self: center;
	}
}

.container-max {
	overflow: hidden;
}

.container {
	table {
		width: 100% !important;
		// tr {
		// 	border-bottom: 1px solid #000;
		// }
		tr:nth-child(odd) {
			background-color: #fff;
		}
	}
}

.centered {
	text-align: center;
	height: 100%;
	align-self: center;
}

.awarenessimg {
	max-height: 500px;
	overflow: hidden;
	@include flexbox;
	justify-content: center;
	align-items: center;

	img {
		max-width: 100%;
	}
}

.footer {
	background-color: #595959;
	margin-top: auto;

	> .row {
		margin: 0;
		color: #fff;

		> div {
			padding: 10px 0;
			@include md {
				padding: 10px 50px;
			}
			width: 100%;
			ul {
				list-style-type: none;
				padding: 0;
				margin: 0;
				@include flexbox;
				justify-content: space-between;
				align-items: center;
				flex-direction: column;
				@include md {
					flex-direction: row;
				}
				width: 100%;
				li {
					a {
						color: #fff;
						width: 100%;
						display: block;
						padding: 4px 8px;
						border-radius: 4px;
						@include transition(0.3s);

						&:hover {
							text-decoration: none;
							background-color: $text-color;
							padding-left: 10px;
						}
					}
				}
			}
		}
	}
}

.postContainer {
	@include flexbox;
	justify-content: flex-start;
	flex-wrap: wrap;

	.postWrapper {
		width: 100%;
		@include md {
			width: 48%;
			margin: 10px 1%;
		}
		@include lg {
			width: 31%;
			margin: 10px 1%;
		}
		// margin: 10px;
		background-color: #fff;
		border-radius: 4px;
		position: relative;
		top: 0;
		@include transition(0.3s);

		.imgWrapper {
			// max-height: 260px;
			overflow: hidden;
			/* new */
			position: relative;
			height: 260px;
			width: 100%;
			img {
				// width: 100%;
				max-width: 105%;
				min-height: 160px;
				height: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 50%;
				margin: auto auto auto -50%;
			}
			/* new end */
		}

		img {
			// width: 100%;
			// height: auto;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		.emptyImg {
			width: 100%;
			min-height: 260px;
			background-color: rgba($mc, 0.7);
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			position: relative;

			&:after {
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				height: 100px;
				margin-top: -50px;
				color: #fff;
				font-size: 80px;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: "\f1ea";
				display: block;
				text-align: center;
			}
		}

		a {
			display: block;
			text-decoration: none;
			height: 100%;
			width: 100%;
			@include flexbox;
			justify-content: flex-start;
			flex-direction: column;

			&:hover {
				text-decoration: none;
			}
		}

		a .title {
			margin-top: 10px;
			font-size: 24px;
			font-weight: 700;
			color: #000;
			padding: 10px;
			align-self: flex-start;
		}

		.text {
			color: $text-color;
			font-size: 16px;
			margin: 0 10px;
			padding-top: 10px;
			border-top: 2px solid rgba($text-color, 0.5);
		}

		.button {
			text-transform: uppercase;
			margin-top: auto;
			font-size: 16px;
			font-weight: 700;
			margin-left: 10px;
			display: block;
			float: left;
			width: 120px;
			text-align: center;
			margin-bottom: 5px;
			padding: 8px 0;
			border-radius: 4px;
			@include transition(0.3s);

			&:hover {
				background-color: #ddd;
				color: #532B7C;
			}
		}

		&:hover {
			background-color: #fff;
			top: -2px;
			box-shadow: 0 1px 3px 1px rgba(60,64,67,.2), 0 2px 8px 4px rgba(60,64,67,.1);
		}
	}
}

.logbookContainer {
	a:hover {
		text-decoration: none;
		.postWrapper {
			top: 2px;
			box-shadow: 0 1px 3px 1px rgba(60,64,67,.2), 0 2px 8px 4px rgba(60,64,67,.1);
		}
	}
		.postWrapper {
			position: relative;
			top: 0;
			@include flexbox;
			@include transition(.3s);
			justify-content: flex-start;
			flex-grow: 0;
			margin: 10px 0;
			background-color: #fff;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			flex-direction: column;
			@include md {
				flex-direction: row;
			};
			.imgWrapper {
				position: relative;
				height: 100%;
				min-height: 150px;
				overflow: hidden;
				flex: 0 0 200px;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;

				@include flexbox;
				justify-content: center;
				align-items: center;

				@include md {
					width: 300px;
					max-height: 150px;
				}
				img {
					max-width: 100%;
					// height: 100%;
					// min-height: 100%;
					height: auto;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;

					@include md {
						max-width: 220px;
						// left: 50%;
						// top: 0;
						// bottom: 0;
						// margin: auto auto auto -110px;
					}
				}

				.emptyImg {
					background-color: $sc;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;

					&:after {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						text-align: center;
						font-size: 0.9em;
						padding: 10px;
						font-size: 80px;
						display: block;
						color: #fff;
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						content: "\f2b9";
					}
				}
			}

			.content {
				.title {
					margin-top: 10px;
					font-size: 24px;
					font-weight: 700;
					color: #000;
					padding: 10px;
					align-self: flex-start;
				}

				.text {
					color: $text-color;
					margin: 0 10px;
					padding-top: 10px;
					border-top: 2px solid rgba($text-color, 0.5);
				}
				.button {
					position: absolute;
					bottom: 0;
					right: 0;
					text-transform: uppercase;
					font-size: 16px;
					font-weight: 700;
					margin-left: 10px;
					display: block;
					float: left;
					width: 120px;
					text-align: center;
					margin: 5px;
					padding: 8px 0;
					border-radius: 4px;
					@include transition(0.3s);
					color: #007bff;
					&:hover {
						background-color: #ddd;
						color: #532B7C;
					}
				}
			}
		}

}

.headerimg {
	max-height: 350px;
	overflow: hidden;
	@include flexbox;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
		height: auto;
	}
}

.content {
	color: $text-color-dark;
}

#news {
	.emptyImg {
		width: 100%;
		min-height: 240px;
		background-color: rgba($mc, 0.7);
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		position: relative;

		&:after {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			height: 100px;
			margin-top: -50px;
			color: #fff;
			font-size: 80px;
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			content: "\f52d";
			display: block;
			text-align: center;
		}
	}
}
