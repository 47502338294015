#navbar {
	@include transition(0.3s);
	height: 90px;

	&.navbar-yellow {
		background-color: $sc;
		background-image: linear-gradient(to right, $sc, $sc-light3);
		padding-left: 0;
		padding-right: 5px;
		@include md {
		padding-right: 10px;
			&.sidebaropen {
				left: 280px;
			}
		}

		.navbar-brand {
			color: #000;
			@include defTextShadow;
		}

		a.nav-link {
			color: #000;
			@include defTextShadow;
			@include transition(0.3s);
			border-radius: 4px;

			&:hover {
				background-color: rgba(#fff, 0.4);
				text-shadow: 2px 2px 2px #fff;
			}
		}

		#sidebarCollapse {
			@include maxlg {
				margin-left: 10px;
			}
			@include transition(0.4s 0.6s);

			&.closed {
				@include transform(rotate(180deg));
			}
			display: block;
			@include maxmd {
				display: none;
			}
		}

		#mobileCollapse {
			@include transition(0.4s 0.6s);
			margin-left: 10px;
			@include md {
				display: none;
			}
			color: #fff;
			background-color: $sc-light2;
			:focus {
				background-color: $sc-light2;
			}
			padding: 20px;
		}
	}

	.title {
		font-size: 24px;
		font-weight: 700;
		color: $mc;
		position: absolute;
		left: 80px;
		@include sm {
			font-size: 32px;
			position: relative;
			left: auto;
		}
		@include md {
			font-size: 38px;
		}
	}

	.logo {
		img {
			max-width: 100px;
		}
	}
}

#sidebar {
	min-width: 280px;
	max-width: 280px;
	height: 100vh;
	background: #0096b0;
	color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	@include transition(0.3s);
	@include flexbox;
	justify-content: flex-start;
	flex-direction: column;
	justify-content: flex-start;
	margin-left: -280px;

	&.active {
		margin-left: 0;
	}
	@include maxmd {
		margin-left: -280px;

		&.active {
			margin-left: -280px;
		}
	}

	a[data-toggle="collapse"] {
		position: relative;
	}

	.dropdown-toggle::after {
		display: block;
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
	}

	.sidebar-header {
		background: #6d7fcc;
		position: relative;

		.imgtext {
			position: absolute;
			text-align: center;
			bottom: 0;
			width: 100%;
			color: #000;
			background-color: rgba(#fff, 0.5);
		}
	}

	ul {
		padding-left: 0;


		p {
			color: #fff;
			padding: 10px;
		}

		li {
			list-style: none;
			line-height: 1.8em;
			position: relative;
			overflow: hidden;

			&.active > a,
			a[aria-expanded="true"] {
				color: #fff;
				background-color: $mc-dark;
			}

			&.active:after {
				// right: -2px;
				right: -15px;
			}

			a {
				z-index: 2;
			}

			&:after {
				pointer-events: none;
				content: '';
				position: absolute;
				@include transition(0.3s);
				top: 50%;
				// triangle
				// margin-top: -10px;
				// height: 18px;
				// width: 18px;
				// right: -15px;
				// border-style: solid;
				// border-width: 9px 12px 9px 0;
				// border-color: transparent #fff transparent transparent;

				//circle
				width: 25px;
				height: 25px;
				margin-top: -12px;
				border-radius: 50%;
				right: -25px;
				border-style: solid;
				border-width: 5px;
				border-radius: 50%;
				border-color: #fff;
				background-color: #fff;
			}

			&:hover {
				a {
					padding-left: 60px;
				}

				&:after {
					// right: 0px;
					right: -12px;
				}
			}

			i {
				margin-right: 12px;
				width: 32px;
			}

			a {
				color: #fff;
				font-size: 0.9em;
				padding: 10px;
				font-size: 1.1em;
				display: block;
				@include transition(0.3s);
				padding-left: 55px;
				&:hover {
					color: #fff;
					background: $mc-light;
					text-decoration: none;
				}
			}
			&:before {
				position: absolute;
				font-size: 0.9em;
				padding: 10px;
				font-size: 1.5em;
				display: block;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: "\f015";
			}
			&:nth-child(1):before { content: "\f015"; }
			&:nth-child(2):before { content: "\f2bb"; }
			&:nth-child(3):before { content: "\f57d"; }
			&:nth-child(4):before { content: "\f1ea"; }
			&:nth-child(5):before { content: "\f187"; }
			&:nth-child(6):before { content: "\f024"; }
			&:nth-child(7):before { content: "\f083"; }
			&:nth-child(8):before { content: "\f02d"; }
		}
	}
}

#mobileNav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-height: 100%;
	overflow-x: scroll;
	background-color: $mc;
	z-index: 1000;
	@include transition(0.8s);
	visibility: hidden;
	opacity: 0;
	top: -100px;
	@include maxmd {
		&.visible {
			visibility: visible;
			opacity: 1;
			top: 90px;
		}
	}

	ul {
		padding: 0;
		li {
			list-style: none;
			line-height: 1.8em;
			position: relative;
			overflow: hidden;

			&.active > a,
			a[aria-expanded="true"] {
				color: #fff;
				background-color: $mc-dark;
			}

			&:hover {
				a {
					padding-left: 65px;
				}
			}

			i {
				margin-right: 12px;
				width: 32px;
			}

			a {
				color: #fff;
				font-size: 0.9em;
				padding: 10px;
				font-size: 1.1em;
				display: block;
				@include transition(0.3s);
				padding-left: 55px;
				&:hover {
					color: #fff;
					background: $mc-light;
					text-decoration: none;
				}
			}
			&:before {
				position: absolute;
				font-size: 0.9em;
				padding: 10px;
				font-size: 1.5em;
				display: block;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: "\f0f3";
				color: #fff;
				pointer-events: none;
			}
			&:nth-child(1):before { content: "\f015"; }
			&:nth-child(2):before { content: "\f57d"; }
			&:nth-child(3):before { content: "\f1ea"; }
			&:nth-child(4):before { content: "\f187"; }
			&:nth-child(5):before { content: "\f024"; }
			&:nth-child(6):before { content: "\f083"; }
			&:nth-child(7):before { content: "\f02d"; }
		}
	}
}

#main {
	width: 100%;
	margin-top: 90px;
	min-height: 100vh;
	@include flexbox;
	flex-direction: column;

	@include transition(0.3s);
	@include md {
		&.sidebar {
			width: calc(100% - 280px);
		}
	}
}
