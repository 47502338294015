$mc: #0096b0;
$mc-light: lighten($mc, 5%);
$mc-light2: lighten($mc, 15%);
$mc-light3: lighten($mc, 30%);
$mc-dark: darken($mc, 5%);
$mc-dark2: darken($mc, 15%);
$mc-dark3: darken($mc, 25%);

$sc: #f18600;
$sc-light: lighten($sc, 5%);
$sc-light2: lighten($sc, 15%);
$sc-light3: lighten($sc, 30%);
$sc-dark: darken($sc, 5%);
$sc-dark2: darken($sc, 15%);
$sc-dark3: darken($sc, 25%);

$text-color: #5f6368;
$text-color-dark: darken($text-color, 10%);

$xs: 0;
$sm: 575px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
@mixin xs {
	@media (min-width: $xs) {
		@content;
	}
}
@mixin sm {
	@media (min-width: $sm) {
		@content;
	}
}
@mixin md {
	@media (min-width: $md) {
		@content;
	}
}
@mixin lg {
	@media (min-width: $lg) {
		@content;
	}
}
@mixin xl {
	@media (min-width: $xl) {
		@content;
	}
}
@mixin maxxs {
	@media (max-width: $xs) {
		@content;
	}
}
@mixin maxsm {
	@media (max-width: $sm) {
		@content;
	}
}
@mixin maxmd {
	@media (max-width: $md) {
		@content;
	}
}
@mixin maxlg {
	@media (max-width: $lg) {
		@content;
	}
}
@mixin flexbox() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
@mixin flex($values) {
	-webkit-box-flex: $values;
	-moz-box-flex: $values;
	-webkit-flex: $values;
	-ms-flex: $values;
	flex: $values;
}
@mixin order($val) {
	-webkit-box-ordinal-group: $val;
	-moz-box-ordinal-group: $val;
	-ms-flex-order: $val;
	-webkit-order: $val;
	order: $val;
}
@mixin transition($transition...) {
	-moz-transition: $transition;
	-o-transition: $transition;
	-webkit-transition: $transition;
	transition: $transition;
}
@mixin transition-property($property...) {
	-moz-transition-property: $property;
	-o-transition-property: $property;
	-webkit-transition-property: $property;
	transition-property: $property;
}
@mixin transition-duration($duration...) {
	-moz-transition-property: $duration;
	-o-transition-property: $duration;
	-webkit-transition-property: $duration;
	transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
	-moz-transition-timing-function: $timing;
	-o-transition-timing-function: $timing;
	-webkit-transition-timing-function: $timing;
	transition-timing-function: $timing;
}
@mixin transition-delay($delay...) {
	-moz-transition-delay: $delay;
	-o-transition-delay: $delay;
	-webkit-transition-delay: $delay;
	transition-delay: $delay;
}
@mixin transform($in) {
	transform: $in;
	-webkit-transform: $in;
	-moz-transform: $in;
	-o-transform: $in;
	-ms-transform: $in;
}
@mixin transform-style($type) {
	-webkit-transform-style: $type;
	-moz-transform-style: $type;
	-o-transform-style: $type;
	-ms-transform-style: $type;
	transform-style: $type;
}
@mixin animation-duration($time) {
	-webkit-animation-duration: $time;
	-moz-animation-duration: $time;
	-ms-animation-duration: $time;
	-o-animation-duration: $time;
	animation-duration: $time;
}
@mixin backgroundTartan() {
	background-image: repeating-linear-gradient(transparent, transparent 50px, rgba(0,0,0,.2) 50px, rgba(0,0,0,.2) 53px, transparent 53px, transparent 63px, rgba(0,0,0,.2) 63px, rgba(0,0,0,.2) 66px, transparent 66px, transparent 116px, rgba(0,0,0,.3) 116px, rgba(0,0,0,.3) 166px, rgba(255,255,255,.1) 166px, rgba(255,255,255,.1) 169px, rgba(0,0,0,.3) 169px, rgba(0,0,0,.3) 179px, rgba(255,255,255,.1) 179px, rgba(255,255,255,.1) 182px, rgba(0,0,0,.3) 182px, rgba(0,0,0,.3) 232px, transparent 232px), repeating-linear-gradient(270deg, transparent, transparent 50px, rgba(0,0,0,.2) 50px, rgba(0,0,0,.2) 53px, transparent 53px, transparent 63px, rgba(0,0,0,.2) 63px, rgba(0,0,0,.2) 66px, transparent 66px, transparent 116px, rgba(0,0,0,.3) 116px, rgba(0,0,0,.3) 166px, rgba(255,255,255,.1) 166px, rgba(255,255,255,.1) 169px, rgba(0,0,0,.3) 169px, rgba(0,0,0,.3) 179px, rgba(255,255,255,.1) 179px, rgba(255,255,255,.1) 182px, rgba(0,0,0,.3) 182px, rgba(0,0,0,.3) 232px, transparent 232px), repeating-linear-gradient(125deg, transparent, transparent 2px, rgba(0,0,0,.1) 2px, rgba(0,0,0,.1) 3px, transparent 3px, transparent 5px, rgba(0,0,0,.1) 5px);
}
@mixin backgroundWeave() {
	background: linear-gradient(45deg, lighten(#0f0, 30%) 12%, transparent 0, transparent 88%, lighten(#dca, 30%) 0), linear-gradient(135deg, transparent 37%, lighten(#a85, 30%) 0, lighten(#a85, 30%) 63%, transparent 0), linear-gradient(45deg, transparent 37%, lighten(#dca, 30%) 0, lighten(#dca, 30%) 63%, transparent 0) lighten(#753, 30%);
	background-size: 25px 25px;
}
@mixin backgroundStar() {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}


@mixin defTextShadow() {
	text-shadow: 1px 1px 2px #fff;
}
